import React, { useState, useEffect } from "react";
import { PopupWrapper, Popup, PopupTitle } from "./popup.styles";
import { CloseWindow } from "../close/close";
import { stopPropagation } from "../helpers";

const popupState = {
  popupTitle: "",
  popupContent: null
};

const subs = [];
export const popupSubscribe = (cb) => {
  subs.push(cb);
};

export const openPopup = (popupTitle, popupContent) => {
  popupState.popupTitle = popupTitle;
  popupState.popupContent = popupContent;
  subs.forEach((sub) => sub(popupState));
};

export const closePopup = () => {
  popupState.popupTitle = "";
  popupState.popupContent = null;
  subs.forEach((sub) => sub(popupState));
};

export const GenericPopup = () => {
  const [popupTitle, setPopupTitle] = useState("");
  const [popupContent, setPopupContent] = useState(null);

  useEffect(() => {
    popupSubscribe((popupState) => {
      setPopupTitle(popupState.popupTitle);
      setPopupContent(popupState.popupContent);
    });
  }, []);

  if (!popupContent) return null;
  return (
    <PopupWrapper onClick={closePopup}>
      <Popup onClick={stopPropagation}>
        <PopupTitle>{popupTitle}</PopupTitle>
        {popupContent}
        <CloseWindow onClick={closePopup} />
      </Popup>
    </PopupWrapper>
  );
};
