import styled from "styled-components";
import { editorBg, editorText } from "../../theme/vars";

export const PopupWrapper = styled.div`
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  animation: popup-wrapper-in 0.2s ease-in-out;

  @keyframes popup-wrapper-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Popup = styled.div`
  display: inline-block;
  position: relative;
  padding: 30px;
  background: ${editorBg};
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: ${editorText};
  animation: popup-in 0.4s ease-in-out;

  @keyframes popup-in {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const PopupTitle = styled.h2`
  margin-top: 0;
  color: ${editorText};
`;
