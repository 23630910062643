export const apiPost = (url, payload, options = {}) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload),
    ...options
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error_message) throw data;
      return data;
    });
};

export const apiGet = (url, options = {}) => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    ...options
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error_message) throw data;
      return data;
    });
};
