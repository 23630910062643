import styled from "styled-components";

export const BasicInput = styled.input`
  margin: 0.3em 0 0 0.3em;
  padding: 3px;
  border: solid 1px rgb(75, 75, 75);
  border-radius: 5px;
  background: rgb(75, 75, 75);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  color: #aaaaaa;
`;

export const SearchInput = styled.input`
  margin: 0.3em 0 0 0.3em;
  padding: 3px;
  border: solid 1px rgb(75, 75, 75);
  border-radius: 5px;
  background: rgb(75, 75, 75);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  color: #aaaaaa;
`;

export const BasicSelect = styled.select`
  margin: 0.3em 0 0 0.3em;
  padding: 3px;
  border: solid 1px rgb(75, 75, 75);
  border-radius: 5px;
  background: rgb(75, 75, 75);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  color: #aaaaaa;
`;
