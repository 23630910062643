import { firebaseClient, SESSION_KEY } from "../../config";
import { browserSessionPersistence, initializeAuth, signInWithEmailAndPassword } from "firebase/auth";

export const firebaseAuth = initializeAuth(firebaseClient, {
  persistence: [browserSessionPersistence],
});

export const processLogin = (email, pass, setUid) => {
  return signInWithEmailAndPassword(firebaseAuth, email, pass)
    .then(res => {
      setUid(res.user.uid);
      sessionStorage.setItem(SESSION_KEY, res.user.uid);
      return res.user;
    })
    .catch(res => {
      console.error(res);
      if (res.code === "auth/invalid-email" || res.code === "auth/wrong-password" || res.code === "auth/user-not-found") {
        alert("Invalid email or password.");
      } else {
        alert("Server Error.");
      }
    });
};

