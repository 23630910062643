import React from "react";
import styled from "styled-components";
import { defaultTransition } from "../../theme/vars";
import { closePopup } from "../popup/popup";
import { AppButton } from "../styles/buttons";

export const CloseButton = styled.button`
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2.6rem;
  background: #cfcfcf;
  cursor: pointer;
  color: #666666;
  line-height: inherit;
  font-size: 1.3rem;
  transition: ${defaultTransition};

  &:hover {
    color: #444444;
  }

  .fas {
    display: block;
  }
`;

export const CloseTopLeft = styled(CloseButton)`
  position: absolute;
  top: -0.6rem;
  left: -0.6rem;
`;

export const CloseMiddleRight = styled(CloseButton)`
  float: right;
  margin-top: 0.3em;
  margin-right: 0.3em;
`;

export const RemovePromptText = styled.p`
  font-weight: normal;
`;

export const CloseWindow = ({ onClick }) => {
  return (
    <CloseTopLeft onClick={onClick}>
      <i className="fas fa-times-circle"></i>
    </CloseTopLeft>
  );
};

export const RemoveItemPrompt = ({ link, action }) => {
  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <RemovePromptText>
        Are you sure you want to remove
        <br />
        {link.domain}
        {link.short} > "{link.url}" ?
      </RemovePromptText>
      <AppButton onClick={closePopup}>Cancel</AppButton>{" "}
      <AppButton onClick={action}>Remove</AppButton>
    </form>
  );
};
