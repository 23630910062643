import React, { useState } from "react";
import {
  RoundedListRow,
  ListLeftButton,
  ListRightButton,
  ListCol1Input,
  ListCol2Input,
  ListCol1Select
} from "../../shared/styles/list";

export const Link = ({ domains, link, onSave, onRemove }) => {
  const [currentLink, setCurrentLink] = useState(link || {});

  const changeName = (event) => {
    setCurrentLink({
      ...currentLink,
      name: event.target.value
    });
  };

  const changeDomain = (event) => {
    setCurrentLink({
      ...currentLink,
      domain: event.target.value
    });
  };

  const changeSlug = (event) => {
    setCurrentLink({
      ...currentLink,
      short: event.target.value
    });
  };

  const changeFullLink = (event) => {
    setCurrentLink({
      ...currentLink,
      url: event.target.value
    });
  };

  return (
    <RoundedListRow>
      <ListLeftButton
        disabled={!currentLink.id}
        onClick={() => onRemove(currentLink)}
      >
        X
      </ListLeftButton>
      <ListRightButton onClick={() => onSave(currentLink)}>
        Save
      </ListRightButton>
      <ListCol1Input
        onInput={changeName}
        placeholder="Name"
        defaultValue={currentLink.name}
      />
      <ListCol2Input
        onInput={changeFullLink}
        placeholder="Full Link"
        defaultValue={currentLink.url}
      />
      <br />
      <ListCol1Select defaultValue={currentLink.domain} onChange={changeDomain}>
        <option value="">Select Domain</option>
        {domains.map((domain) => (
          <option key={domain.id} value={domain.value}>
            {domain.value}
          </option>
        ))}
      </ListCol1Select>
      <ListCol1Input
        onInput={changeSlug}
        placeholder="Short path"
        defaultValue={currentLink.short}
      />{" "}
      {currentLink.url ? (
        <>
          Your link:{" "}
          <a href={currentLink.url} target="_blank">
            {currentLink.domain && <span>{currentLink.domain}</span>}
            {currentLink.short && <span>{currentLink.short}</span>}
          </a>
        </>
      ) : (
        <>
          Your link: {currentLink.domain && <span>{currentLink.domain}</span>}
          {currentLink.short && <span>{currentLink.short}</span>}
        </>
      )}
    </RoundedListRow>
  );
};
