import styled from "styled-components";
import { editorBg, editorBorder, editorText, toolBtnFontSize } from "./vars";

export const MainLayout = styled.div`
  width: 100%;
  height: 100%;
  background: ${editorBg};
  padding: 1% 5%;
`;

// export const MainLayout = styled.div`
//   display: grid;
//   grid-template-columns: 50px 1fr;
//   width: 100%;
//   height: 100%;
// `;

// export const AppToolbar = styled.div`
//   background: ${editorBg};
//   border-right: solid 1px ${editorBorder};
//   border-radius: 0 3px 0 0;
//   color: ${editorText};
// `;

// export const AppContent = styled.div`
//   overflow: hidden;
//   display: grid;
//   grid-template-rows: ${(props) => (props.isOpen ? "50% 50%" : "100% 0%")};
//   height: 100%;
// `;

// export const AppFrameWrapper = styled.div``;

// export const AppFrame = styled.iframe`
//   display: block;
//   position: relative;
//   margin: auto;
//   width: 100%;
//   height: 100%;
//   border: none;
// `;

// export const AppEditor = styled.div`
//   background: ${editorBg};
//   border-top: solid 1px ${editorBorder};
//   color: ${editorText};
// `;

// export const ToolButton = styled.button`
//   margin: 0;
//   padding: 1.5em 0 0.5em;
//   width: 100%;
//   border: none;
//   border-radius: 3px;
//   background: none;
//   line-height: 2.5em;
//   font-size: ${toolBtnFontSize};
//   text-transform: uppercase;
//   color: ${editorText};
//   cursor: pointer;

//   &:hover {
//     background: rgba(255, 255, 255, 0.1);
//     border: none;
//     outline: none;
//   }

//   &:active {
//     background: rgba(255, 255, 255, 0.1);
//     border: none;
//     outline: none;
//   }

//   &:focus {
//     border: none;
//     outline: none;
//   }

//   .fas {
//     display: block;
//     font-size: 3em;
//   }
// `;
