import React, { useEffect, useState } from "react";
import { onValue, ref, set, push, child, remove } from "firebase/database";
import { db } from "../../config";
import { apiGet } from "../../shared/api/api";
import { RemoveItemPrompt } from "../../shared/close/close";
import { closePopup, GenericPopup, openPopup } from "../../shared/popup/popup";
import { AddButton } from "../../shared/styles/buttons";
import { ListContainer } from "../../shared/styles/list";
import { MainLayout } from "../../theme/styles";
import { Filters, FILTER_TYPES, SORT_OPTIONS } from "./Filters";
import { Link } from "./Link";

const newLink = () => ({
  domain: "",
  short: "",
  url: "",
  created: new Date().getTime(),
});

const sortByType = {
  [SORT_OPTIONS.CREATED_ASC.id]: (a, b) => a.created - b.created,
  [SORT_OPTIONS.CREATED_DESC.id]: (a, b) => b.created - a.created,
  [SORT_OPTIONS.NAME_ASC.id]: (a, b) => (a.name > b.name ? 1 : -1),
  [SORT_OPTIONS.NAME_DESC.id]: (a, b) => (a.name < b.name ? 1 : -1),
  [SORT_OPTIONS.SHORT_ASC.id]: (a, b) => (a.short > b.short ? 1 : -1),
  [SORT_OPTIONS.SHORT_DESC.id]: (a, b) => (a.short < b.short ? 1 : -1),
};

export const LinkList = () => {
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState(SORT_OPTIONS.CREATED_DESC);
  const [domains, setDomains] = useState([]);
  const [links, setLinks] = useState([]);

  const domainsRef = ref(db, "domains");
  const linksRef = ref(db, "links");

  useEffect(() => {
    onValue(domainsRef, (snapshot) => {
      const byId = snapshot.val();

      if (byId) {
        const list = Object.entries(byId).map(([id, entry]) => {
          return {
            id,
            value: entry,
          };
        });
        setDomains(list);
      }
    });
    onValue(linksRef, (snapshot) => {
      const byId = snapshot.val();
      if (byId) {
        // setLinksById(byId);

        const list = Object.entries(byId).map(([id, entry]) => {
          return {
            ...entry,
            id,
          };
        });
        setLinks(list);
      }
    });
  }, []);

  const addOrUpdateLink = (link) => {
    const update = link.id
      ? set(child(linksRef, link.id), link)
      : push(linksRef, link);
    update.then(() => {
      apiGet(`${link.domain}deploy.php`);
    });
  };

  const removeLink = (link) => {
    remove(child(linksRef, link.id)).then(() => {
      apiGet(`${link.domain}deploy.php`);
    });
    closePopup();
  };

  const openRemovePrompt = (link) => {
    openPopup(
      "Remove Link",
      <RemoveItemPrompt link={link} action={() => removeLink(link)} />
    );
  };

  const addNewLinkItem = () => {
    setLinks([newLink(), ...links]);
  };

  const filteredLinks = links.filter((link) => {
    return filters.reduce((prev, filter) => {
      if (filter.type === FILTER_TYPES.name) return link.name === filter.value;
      if (filter.type === FILTER_TYPES.domain) {
        return link.domain === filter.value;
      }
      if (filter.type === FILTER_TYPES.search) {
        let includes = false;
        if (link.name && link.name.includes(filter.value)) includes = true;
        if (link.domain && link.domain.includes(filter.value)) includes = true;
        if (link.url && link.url.includes(filter.value)) includes = true;
        if (link.short && link.short.includes(filter.value)) includes = true;
        return includes;
      }
      return prev;
    }, true);
  });
  const sortedLinks = filteredLinks.sort(sortByType[sort]);

  return (
    <>
      <MainLayout>
        <ListContainer>
          <h1 className="text-center">
            Your Links <AddButton onClick={addNewLinkItem}>ADD +</AddButton>
          </h1>
          <Filters
            links={links}
            domains={domains}
            setFilters={setFilters}
            setSort={setSort}
          />
          {sortedLinks.map((link, indx) => (
            <Link
              key={link.id || `new-${indx}`}
              link={link}
              domains={domains}
              onSave={addOrUpdateLink}
              onRemove={openRemovePrompt}
            />
          ))}
        </ListContainer>
      </MainLayout>
      <GenericPopup />
    </>
  );
};
