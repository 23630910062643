import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

export const SESSION_KEY = "tlssn";
export const getCurrentSession = () => sessionStorage.getItem(SESSION_KEY);

const firebaseConfig = {
  apiKey: "AIzaSyDqI3QP-3Gpw4sOOvrKFHHR6BxJTGrkgg0",
  authDomain: "tiny-link-fe1f3.firebaseapp.com",
  databaseURL: "https://tiny-link-fe1f3-default-rtdb.firebaseio.com",
  projectId: "tiny-link-fe1f3",
  storageBucket: "tiny-link-fe1f3.appspot.com",
  messagingSenderId: "160693461982",
  appId: "1:160693461982:web:3bccc572bbce1ac363734f",
  measurementId: "G-2BY7753ZQ1"
};
export const firebaseClient = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseClient);