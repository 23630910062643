import styled from "styled-components";
import { editorBorder, defaultTransition } from "../../theme/vars";
import { BasicInput, BasicSelect } from "./input";

export const ListContainer = styled.ul`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 764px;
  height: auto;
  list-style: none;
`;

export const ListRow = styled.li`
  margin: 0;
  padding: 0;
`;

export const RoundedListRow = styled(ListRow)`
  margin-bottom: 20px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
  background: ${editorBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  @keyframes row-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation: row-in 0.4s ease-in-out;

  span {
    @keyframes span-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: span-in 0.3s ease-in-out;
  }
`;

export const RoundedFilterRow = styled(ListRow)`
  padding: 0.5em 1em 0.7em;
  margin-bottom: 20px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  background: ${editorBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  @keyframes row-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation: row-in 0.4s ease-in-out;

  span {
    @keyframes span-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: span-in 0.3s ease-in-out;
  }
`;

export const FilterTitle = styled.span`
  display: inline-block;
  width: 10%;

  @media screen and (max-width: 600px) {
    width: 20%;
  }
`;

export const ListLeftButton = styled.button`
  float: left;
  padding: 0 0.5em 0 0.8em;
  height: 60px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: rgb(75, 75, 75);
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #aaaaaa;
  transition: ${defaultTransition};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    outline: none;
    border: none;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const ListRightButton = styled.button`
  float: right;
  padding: 0 1.3em 0 1em;
  height: 60px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #00aa00;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #005000;
  transition: ${defaultTransition};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    outline: none;
    border: none;
  }
`;

export const ListCol1Input = styled(BasicInput)`
  width: 140px;
`;

export const ListCol2Input = styled(BasicInput)`
  width: calc(100% - 66px - 27px - 0.9em - 140px);
`;

export const ListCol1Select = styled(BasicSelect)`
  width: 140px;
`;
