import styled from "styled-components";

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100%;

  .auth-grid {
    display: table;
    width: 100%;
    height: 100%;
    background: #363636;

    h1 {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
    }

    .input,
    .textarea {
      padding: 15px;
      border: solid 1px #efefef;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    }

    .input:disabled {
      background: #efefef;
    }

    .input {
      margin: 7px 0;
      min-width: 270px;
      max-width: 80%;
    }

    .textarea {
      width: 100%;
      min-height: 100px;
    }
  }

  .login-screen {
    display: table-cell;
    vertical-align: center;
    padding: 20px;
    width: 100%;
    text-align: center;
  }

  .login-screen p {
    text-align: center;
  }

  .button {
    margin: 7px 0;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    outline: none;
    background: #dddddd;
    text-align: center;
    text-transform: uppercase;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
    color: #363636;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    .login-txt {
      display: block;
    }

    .loading-txt {
      display: none;
    }

    &.loading {
      background: #cccccc;
      color: #aaaaaa;

      .login-txt {
        display: none;
      }

      .loading-txt {
        display: block;
      }
    }
  }

  .button-large {
    min-width: 150px;
    max-width: 80%;
  }

  .error {
    color: #cc3333;
  }
`;
