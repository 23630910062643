import React, { useState } from "react";
import { processLogin } from "./Login.helpers";
import { Spinner } from "../../shared/Spinner";
import { AuthWrapper } from "./Login.styles";

export const LoginView = ({ setUid }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState("");

  const login = (event) => {
    event.preventDefault();

    if (email && password) {
      setAuthLoading(true);
      processLogin(email, password, setUid)
        .then(() => {
          setAuthLoading(false);
        })
        .catch((err) => {
          setAuthLoading(false);
          if (
            err.code === "auth/wrong-password" ||
            err.code === "auth/user-not-found"
          ) {
            setAuthError("Invalid email or password.");
          } else {
            setAuthError(err.message);
          }
        });
    }
  };

  const changeEmail = (event) => {
    setAuthError("");
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setAuthError("");
    setPassword(event.target.value);
  };

  return (
    <AuthWrapper>
      <div className="auth-grid">
        <form onSubmit={login} id="loginForm" className="login-screen">
          <h1 className="text-center">Login to Link Shortener</h1>
          {authError && (
            <p id="authError" className="error">
              {authError}
            </p>
          )}
          <p>
            <input
              onChange={changeEmail}
              id="email"
              name="email"
              className="input"
              placeholder="Email"
            />
          </p>
          <p>
            <input
              onChange={changePassword}
              id="password"
              name="password"
              className="input"
              placeholder="Password"
              type="password"
            />
          </p>
          <p>
            <button
              css="'loading': authLoading"
              className={`button button-large ${authLoading ? "loading" : ""}`}
              type="submit"
            >
              {authLoading && (
                <span className="loading-txt">
                  <Spinner /> Loading...
                </span>
              )}
              {!authLoading && <span className="login-txt">Login</span>}
            </button>
          </p>
        </form>
      </div>
    </AuthWrapper>
  );
};
