import styled from "styled-components";
import { defaultTransition } from "../../theme/vars";

export const AddButton = styled.button`
  float: right;
  padding: 0 1em;
  height: 40px;
  border-radius: 50px;
  background: #00aa00;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #005000;
  transition: ${defaultTransition};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    outline: none;
    border: none;
  }
`;

export const AppButton = styled.button`
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  background: #333333;
  font-size: 13px;
  line-height: 35px;
  color: #777777;

  &:hover {
    background: #454545;
  }
`;
