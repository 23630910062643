import React from "react";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: inline-block;
  max-width: 50px;
`;

export const Spinner = () => (
  <SpinnerWrapper>
    <svg className="editor-spinner" viewBox="0 0 64 64">
      <g strokeWidth="0">
        <circle cx="24" cy="0" transform="translate(32,32)" r="4.9618">
          <animate
            attributeName="r"
            dur="750ms"
            values="8;7;6;5;4;3;2;1;8"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="16.970562748477143"
          cy="16.97056274847714"
          transform="translate(32,32)"
          r="5.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="1;8;7;6;5;4;3;2;1"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="1.4695761589768238e-15"
          cy="24"
          transform="translate(32,32)"
          r="6.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="2;1;8;7;6;5;4;3;2"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-16.97056274847714"
          cy="16.970562748477143"
          transform="translate(32,32)"
          r="7.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="3;2;1;8;7;6;5;4;3"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-24"
          cy="2.9391523179536475e-15"
          transform="translate(32,32)"
          r="1.26738"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="4;3;2;1;8;7;6;5;4"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-16.970562748477143"
          cy="-16.97056274847714"
          transform="translate(32,32)"
          r="1.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="5;4;3;2;1;8;7;6;5"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-4.408728476930472e-15"
          cy="-24"
          transform="translate(32,32)"
          r="2.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="6;5;4;3;2;1;8;7;6"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="16.970562748477136"
          cy="-16.970562748477143"
          transform="translate(32,32)"
          r="3.9618"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="7;6;5;4;3;2;1;8;7"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </g>
    </svg>
  </SpinnerWrapper>
);
