import { getCurrentSession } from "./config";
import { LoginView } from "./views/auth/Login";
import "./styles.css";
import { useState } from "react";
import { LinkList } from "./views/links/LinkList";

export default function App() {
  const [uid, setUid] = useState(getCurrentSession());

  if (!uid) return <LoginView setUid={setUid} />;

  return <LinkList />;
}
