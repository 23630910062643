import React, { useState } from "react";
import { SearchInput, BasicSelect } from "../../shared/styles/input";
import { RoundedFilterRow, FilterTitle } from "../../shared/styles/list";

export const FILTER_TYPES = {
  name: "name",
  domain: "domain",
  search: "search"
};

export const SORT_OPTIONS = {
  CREATED_ASC: { id: "CREATED_ASC", label: "Created Asc" },
  CREATED_DESC: { id: "CREATED_DESC", label: "Created Des" },
  NAME_ASC: { id: "NAME_ASC", label: "Name Asc" },
  NAME_DESC: { id: "NAME_DESC", label: "Name Des" },
  SHORT_ASC: { id: "SHORT_ASC", label: "Short Path Asc" },
  SHORT_DESC: { id: "SHORT_DESC", label: "Short Path Des" }
};

export const SORT_OPTIONS_LIST = Object.values(SORT_OPTIONS);

let nameFilter, domainFilter, searchFilter;

export const Filters = ({ links, domains, setFilters, setSort }) => {
  const changeFilter = (type, value) => {
    const filters = [];

    if (type === FILTER_TYPES.name) nameFilter = value;
    if (type === FILTER_TYPES.domain) domainFilter = value;
    if (type === FILTER_TYPES.search) searchFilter = value;

    if (nameFilter)
      filters.push({
        type: FILTER_TYPES.name,
        value: type === FILTER_TYPES.name ? value : nameFilter
      });

    if (domainFilter)
      filters.push({
        type: FILTER_TYPES.domain,
        value: type === FILTER_TYPES.domain ? value : domainFilter
      });

    if (searchFilter)
      filters.push({
        type: FILTER_TYPES.search,
        value: type === FILTER_TYPES.search ? value : searchFilter
      });

    setFilters(filters);
  };

  return (
    <RoundedFilterRow>
      <FilterTitle>Filter by:</FilterTitle>
      <BasicSelect
        onChange={(event) =>
          changeFilter(FILTER_TYPES.name, event.target.value)
        }
      >
        <option value="">Name</option>
        {links.map((link) =>
          link.name ? (
            <option key={link.id} value={link.name}>
              {link.name}
            </option>
          ) : null
        )}
      </BasicSelect>
      <BasicSelect
        onChange={(event) =>
          changeFilter(FILTER_TYPES.domain, event.target.value)
        }
      >
        <option value="">Domain</option>
        {domains.map((domain) => (
          <option key={domain.id} value={domain.value}>
            {domain.value}
          </option>
        ))}
      </BasicSelect><br/>
      <FilterTitle>Sort by:</FilterTitle>
      <BasicSelect
        defaultValue={SORT_OPTIONS.CREATED_DESC.id}
        onChange={(event) => setSort(event.target.value)}
      >
        {SORT_OPTIONS_LIST.map((soption) => (
          <option key={soption.id} value={soption.id}>
            {soption.label}
          </option>
        ))}
      </BasicSelect><br/>
      <FilterTitle>Search:</FilterTitle>
      <SearchInput
        onInput={(event) =>
          changeFilter(FILTER_TYPES.search, event.target.value)
        }
      />
    </RoundedFilterRow>
  );
};
